import pick from 'lodash/pick';
import { setup } from '@contentful/dam-app-base';

const CTA = 'Select an asset on IntelligenceBank';

const FIELDS_TO_PERSIST = [
    'filetype',
    'filename',
    'filesize',
    'height',
    'name',
    'origfilename',
    'type',
    'url',
    'download_url',
    'thumbnail',
    'width',
    'description',
];

function makeThumbnail(attachment) {
    if (attachment.thumbnail) {
        const thumbnail = attachment.thumbnail || attachment.thumbnail;
        const url = typeof thumbnail === 'string' ? thumbnail : undefined;
        const alt = attachment.filename;
        return [url, alt];
    }
}

function renderDialog(sdk) {
    const config = sdk.parameters.invocation;
    let embed_url = 'https://ucprod.intelligencebank.com/app/?app=contentful';
    if (config.environment === 'Staging') {
        embed_url = 'https://ucstaging.intelligencebank.com/app/?app=contentful';
    }
    const container = document.createElement('div');
    const iframe = document.createElement('iframe');
    iframe.id = 'intelligencebank-embed';
    iframe.className = 'iframe-container';
    iframe.src = embed_url;
    iframe.width = 650;
    iframe.height = 650;
    iframe.style.border = 'none';
    container.appendChild(iframe);
    document.body.appendChild(container);
    sdk.window.startAutoResizer();
    window.addEventListener('message', e => {
        if (e.source !== iframe.contentWindow) {
            return;
        }
        const { data } = e;
        if (data.id !== 0) {
            let resultarray = [];
            let f = JSON.parse(data);
            resultarray.push(f);
            sdk.close(resultarray);
        }
    });

}

async function openDialog(sdk, _currentValue, config) {
    const result = await sdk.dialogs.openCurrentApp({
        position: 'center',
        title: CTA,
        shouldCloseOnOverlayClick: true,
        shouldCloseOnEscapePress: true,
        parameters: {
            ...config
        },
        width: 650,
        allowHeightOverflow: true
    });

    if (!Array.isArray(result)) {
        return [];
    }
    return result.map(asset => pick(asset, FIELDS_TO_PERSIST));
}

setup({
    cta: CTA,
    name: 'IntelligenceBank',
    logo: 'intelligencebank-favicon.png',
    color: '#f68d32',
    description: 'The Intelligencebank app is a widget that allows editors to select media from their Intelligencebank account. Select a file on Intelligencebank and designate the assets that you want your entry to reference.',
    parameterDefinitions: [{
        "id": "environment",
        "name": "Environment",
        "description": "Please select the IntelligenceBank environment for the app",
        "type": "List",
        "value": "Staging,Production",
        "required": true,
        "default": "Production"
    }, ],
    validateParameters: () => {},
    makeThumbnail,
    renderDialog,
    openDialog,
    isDisabled: () => false,
});